import Accordion from 'accordion-js';
import 'accordion-js/dist/accordion.min.css';

// config and initialize
const accordions = [...document.querySelectorAll('.accordion')]

accordions.forEach(accordionEl => {
  const tabs = accordionEl.getAttribute('data-tabs') || false
  const showMultiple = !!accordionEl.getAttribute('data-showmultiple') || false
  const onInit = accordionEl.getAttribute('data-oninit') || [0]

  const accordion = new Accordion(accordionEl, {
    duration: 600,
    elementClass: 'accordion__item',
    triggerClass: 'accordion__trigger',
    panelClass: 'accordion__panel',
    showMultiple: showMultiple, // max 1 tab always open
    collapse: showMultiple, // min 1 tab always open (if multiple, allow collapsing)
    openOnInit: onInit, // accepts an array of indexes
    beforeOpen: (currentElement) => {
      if (tabs) {
        const event = new CustomEvent('changeActive', { detail: Array.prototype.indexOf.call(currentElement.parentElement.children, currentElement) })
        const target = document.querySelector(tabs)
        target.dispatchEvent(event)
      }
    }
  })

  if (tabs) {
    accordionEl.addEventListener('changeActive', (e) => {
      const currentElement = accordionEl.querySelector('.is-active')
      accordion.close(Array.prototype.indexOf.call(accordionEl.children, currentElement))
      accordion.open(e.detail)
    }, false)
  }
})
