// import libraries
import './partials/libs'
import { debounce } from 'lodash'
import 'flowbite'

// import my modules
import { scrollClasses, inViewport, viewportHeight } from './partials/utils'

// import './js/lightbox'
import './partials/tabs'
import './partials/herohome'
import './partials/carousel'
import './partials/accordion'
import './partials/tooltips'

import './partials/nav'

// there are still duplicated insertions of the main script
if (!window.mainscriptLandLoaded) {
  window.mainscriptLandLoaded = true

  document.addEventListener(
    'DOMContentLoaded',
    () => {
      // Navbar on scroll
      scrollClasses()
      inViewport()

      // Consistent -vh units
      new viewportHeight()
    },
    false
  )

  /**
   * Indicate if sticky element is currently stuck
   */

  const stickyEls = document.querySelectorAll('[class*="sticky"]')

  const stickyReferences = [...stickyEls].map(el => {
    const stickyReference = document.createElement('span')
    el.parentElement.insertBefore(stickyReference, el)
    return stickyReference
  })
  //document.querySelectorAll('[class*="stuck"]')

  //to check when element get's position sticky
  const observer = new IntersectionObserver((entries) => {
    // no intersection 
    if (entries[0].intersectionRatio === 0) {
      // console.log(entries[0], 'stuck');
      entries[0].target.nextSibling.setAttribute('data-stuck', true);
      
    } else if (entries[0].intersectionRatio === 1) {
      entries[0].target.nextSibling.setAttribute('data-stuck', false);
      // console.log(entries[0], 'unstuck');
    }
  }, {
    threshold: [0, 1]
  });

  stickyReferences.forEach(element => observer.observe(element));

  /**
   * DeepL badge - toggling of deepl popup 
   */
  const deeplBadge = document.querySelector('[href="#deepl-popup"]')
  const deeplPopup = document.querySelector('#deepl-popup')

  // click on button
  deeplBadge?.addEventListener('click', e => {
    e.preventDefault()
    deeplPopup?.classList.toggle('open')
  }, false)

  // outside
  document.addEventListener('click', e => {
    if (!e.target.closest('.deepl')) deeplPopup?.classList.remove('open')
  }, false)


  /**
   * Chat config
   */
  if (window.fcWidget) {
    // console.log('starting chat...');
    window.fcWidget.setConfig({ headerProperty: { hideChatButton: false } });
  }

  /**
   * iframe mode (querystring - https://www.tabidoo.cloud?mode=iframe") - hide header and footer 
   */
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  // get the value of "iframe" 
  let value = params?.mode; // "iframe"
  if(value === 'iframe') {
    const navbar = document.querySelector('.navbar');
    navbar?.classList.toggle('hide');
    const footer = document.querySelector('footer');
    footer?.classList.toggle('hide');
  }
}