import Swiper from 'swiper';
import { EffectCreative, Controller } from 'swiper/modules';

// css modules to import: https://swiperjs.com/swiper-api#css-styles
import 'swiper/swiper.css';
import 'swiper/modules/effect-creative.css';
import 'swiper/modules/controller.css';

// config and initialize

const nav = document.querySelector('[data-herohome-nav]');
const slider = document.querySelector('[data-herohome-slider]');

const navSlidesNo = nav?.querySelectorAll('.swiper-slide').length;
const middleIndex = Math.floor((navSlidesNo - 1) / 2);

const navSwiper = new Swiper(nav, {
  modules: [Controller],
  grabCursor: false,
  speed: 800,
  slidesPerView: 'auto',
  slidesPerGroup: 1,
  spaceBetween: 4.5,
  centeredSlides: true,
  allowTouchMove: false,
  slideToClickedSlide: true,
  on: {
    init: (swiper) => {},
    resize: (swiper) => {},
  },
});

window.nav = navSwiper;

const sliderSwiper = new Swiper(slider, {
  modules: [EffectCreative, Controller],
  grabCursor: true,
  speed: 800,
  // loop: true,
  slidesPerView: 1,
  thumbs: {
    swiper: navSwiper,
  },
  effect: 'creative',
  creativeEffect: {
    prev: {
      shadow: false,
      translate: ['-3rem', 0, 0],
      opacity: 0,
    },
    next: {
      translate: ['3rem', 0, 0],
      opacity: 0,
    },
  },
  controller: {
    control: navSwiper,
  },
  on: {
    afterInit: (swiper) => {
      // initilize playback on the first slide
      swiper.slides[0]?.querySelector('video')?.play();

      // slider autoplay replacement – through video end event
      // for all the videos, listen to the end event
      swiper.slides.forEach((slide, idx) => {
        const video = slide.querySelector('video');

        // on video end, slide to the next slide (account for desktop, when slideTo does not work)
        video?.addEventListener('ended', () => {
          const nextSlide = idx + 1 < swiper.slides?.length ? idx + 1 : 0;
          swiper.slideTo(nextSlide);
          swiper.updateActiveIndex(nextSlide);
        });
      });
    },
    slideChange: (swiper) => {
      const prevVideo =
        swiper.slides[swiper.previousIndex]?.querySelector('video'); // video element in the previous slide
      const video = swiper.slides[swiper.activeIndex]?.querySelector('video'); // video element in the current slide

      if (video) video.currentTime = 0;
      video?.play();
      prevVideo?.pause();
    },
  },
});

if (navSwiper.controller && sliderSwiper.controller) {
  navSwiper.controller.control = sliderSwiper;
  sliderSwiper.controller.control = navSwiper;
}
