import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward.css';
import 'tippy.js/themes/light-border.css';

tippy('[data-tippy-content]', {
  theme: 'light-border',
  arrow: true,
  animation: 'shift-toward',
  delay: [125, 250],
});
