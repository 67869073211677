import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

// there are still duplicated insertions of the main script, so this section as well
if (!window.mainscriptLandLoaded) {
  /**
   * Navbar
   */

  // highlight current page in navigation (heuristics)
  // & highlight parent page for subitems
  const navItems = document.querySelectorAll('.navbar nav > ul > li')
  const navSubItems = document.querySelectorAll('.navbar nav > ul > li > ul.subnav > li')

  document.addEventListener(
    'DOMContentLoaded',
    () => {
      navItems.forEach(item => {
        const href = item.querySelector('a').getAttribute('href')
        if (window.location.pathname ===  href) {
          item.classList.add('current')
        }
      })

      navSubItems.forEach(item => {
        const href = item.querySelector('a').getAttribute('href')
        if (window.location.pathname ===  href) {
          item.parentElement.closest('li').classList.add('current')
          item.classList.add('current')
        }
      })
    },
    false
  )


  // prevent clicking action for items without target
  const emptyItems = document.querySelectorAll('.navbar a[href="#"]')
  emptyItems.forEach(item => item.addEventListener('mousedown', e => {
    e.preventDefault() // prevents automatic focus

    if (document.activeElement === item) {
      item.blur()
    } else {
      item.focus({focusVisible: false})
    }
  }))

  emptyItems.forEach(item => item.addEventListener('click', e => {
    e.preventDefault() // prevents href action
  }))


  // respo navbar
  const navbarBtn = document.querySelector('.navbar__btn')
  navbarBtn?.addEventListener('click', e => {
    e.preventDefault()
    document.body.classList.toggle('nav--open')

    if (document.body.classList.contains('nav--open')) {
      const respoInner = document.querySelector('.navbar__respo__inner')
      disableBodyScroll(respoInner)
    } else {
      clearAllBodyScrollLocks()
    }
  })

  // TODO: onpage menu
}