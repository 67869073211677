// import Swiper, { Navigation, Pagination, Parallax, EffectCreative } from 'swiper'
import Swiper from 'swiper';
import {
  Pagination,
  Autoplay,
  EffectFade,
  EffectFlip,
  EffectCreative,
} from 'swiper/modules';

// css modules to import: https://swiperjs.com/swiper-api#css-styles
import 'swiper/swiper.css';
import 'swiper/modules/effect-fade.css';
import 'swiper/modules/effect-flip.css';
import 'swiper/modules/effect-creative.css';

// config and initialize
document.querySelectorAll('[data-tabs-items]').forEach((tabs) => {
  const parent = tabs.closest('[data-tabs]');
  const pagination = parent.querySelector('[data-tabs-nav]');

  // pairing with an accordion
  const accordion = parent.getAttribute('data-accordion') || false;
  let accordionSettings = {};

  if (accordion) {
    accordionSettings = {
      autoplay: false,
    };
  }

  // effects
  const effectAttr = parent.getAttribute('data-effect') || false;
  let effect = {};

  if (effectAttr === 'flip') {
    effect = {
      effect: 'flip',
      flipEffect: {
        slideShadows: false,
      },
    };
  } else if (effectAttr === 'creative') {
    effect = {
      effect: 'creative',
      creativeEffect: {
        //https://swiperjs.com/swiper-api#creative-effect
        prev: {
          shadow: false,
          // translate: ['-33%', 0, 0],
          translate: ['-3rem', 0, 0],
          opacity: 0,
        },
        next: {
          // translate: ['33%', 0, 0],
          translate: ['3rem', 0, 0],
          opacity: 0,
        },
      },
    };
  } else {
    // default effect = crossfade
    effect = {
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    };
  }

  let slideNames = [];
  const swiper = new Swiper(tabs, {
    modules: [Pagination, Autoplay, EffectFade, EffectFlip, EffectCreative],
    autoplay: {
      delay: 4000,
    },
    // grabCursor: false,
    speed: 600,
    direction: 'horizontal',
    ...effect,
    ...accordionSettings,
    pagination: {
      el: pagination,
      clickable: true,
      renderBullet: (idx, className) => {
        return (
          '<li class="' +
          className +
          '"><span>' +
          slideNames[idx] +
          '</span></li>'
        );
      },
      // dynamicBullets: true,
      // dynamicMainBullets: 3
    },
    on: {
      beforeInit: (swiper) => {
        slideNames = [...swiper.el.querySelectorAll('[data-title]')].map(
          (tab) => tab.getAttribute('data-title')
        );
      },

      slideChange: (swiper) => {
        if (accordion) {
          const event = new CustomEvent('changeActive', {
            detail: swiper.activeIndex,
          });
          const target = document.querySelector(accordion);
          target.dispatchEvent(event);
        }

        // this caused unwanted overscroll of parent elemnts
        // pagination
        //   .querySelector('.swiper-pagination-bullet-active')
        //   .scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'nearest',
        //     inline: 'center',
        //   });

        // keep the active bullet visible, if overflowes
        if (pagination) {
          const activeBullet = pagination.querySelector(
            '.swiper-pagination-bullet-active'
          );

          const paginationRect = pagination.getBoundingClientRect();
          const bulletRect = activeBullet.getBoundingClientRect();

          // Calculate the center points
          const paginationCenter =
            paginationRect.left + paginationRect.width / 2;
          const bulletCenter = bulletRect.left + bulletRect.width / 2;

          // Calculate the offset needed to center the bullet
          const offset = bulletCenter - paginationCenter;

          // Only scroll if the active bullet is not already centered
          if (
            Math.abs(offset) > bulletRect.width / 2 ||
            bulletCenter < paginationCenter
          ) {
            // Scroll by the offset to center the bullet
            pagination.scrollBy({
              left: offset,
              behavior: 'smooth',
            });
          }
        }
      },
    },
  });

  if (accordion) {
    parent.addEventListener(
      'changeActive',
      (e) => {
        swiper.slideTo(e.detail);
      },
      false
    );
  }
});
