import { throttle, debounce } from 'lodash'

/**
 * Adds scroll-based classes
 * - scroll-up, scroll-down, scroll-top
 *
 * @param {bar} HTMLElement
 */
export function scrollClasses(bar = null) {
  const target = bar || document.body
  const barHeight = bar?.offsetHeight || 375
  let refScrollY = 0

  const handler = () => {
    const newScrollY = window.scrollY || window.pageYOffset

    if (newScrollY > barHeight) {
      target.classList.remove('scroll-top')
      if (newScrollY > refScrollY) {
        target.classList.remove('scroll-up')
        target.classList.add('scroll-down')
      } else {
        target.classList.remove('scroll-down')
        target.classList.add('scroll-up')
      }

      refScrollY = newScrollY
    } else {
      target.classList.add('scroll-top')
    }
  }

  handler()
  document.addEventListener('scroll', throttle(handler, 300), false)
}


/**
 * Adds viewport-based classes to elements
 * - in-viewport
 *
 * @param {selector} String
 */
export function inViewport(selector = '[class*="not-viewport"], [data-slide-in], [data-fade-in]') {
  const elements = document.querySelectorAll(selector)
  const staggerDelay = 150;

  const observer = new window.IntersectionObserver((entries) => {
      let items = 0;
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove('below-viewport', 'above-viewport')
          entry.target.classList.add('in-viewport')
          entry.target.style.transitionDelay = `${items * staggerDelay}ms`

          entry.target.addEventListener('transitionend', () => {
            entry.target.style.transitionDelay = `0ms`;
          })

          items++
          return
        } else {
          entry.target.classList.remove('in-viewport')
          entry.target.style.animationDelay = `0ms`

          if (entry.boundingClientRect.top > 0) {
            entry.target.classList.add('below-viewport')
          } else {
            entry.target.classList.add('above-viewport')
          }
        }
      })
    }, {
      root: null,
      rootMargin: '0% 0% -6.66667% 0%',
      threshold: 0,
    })

  elements.forEach(element => observer.observe(element))
}

export class viewportHeight {
  constructor() {
    this.vh = window.innerHeight
    this.set()

    window.addEventListener('resize', () => this.refresh(), false)
    window.addEventListener('orientationchange', () => this.refresh(), false)
  }

  refresh() {
    // if we're on a touchscreen, ignore bar toggling - only reflect changes larger than that
    // on iOS the bar's height seems to be 114px

    if (document.documentElement.classList.contains('can-touch-this') && Math.abs(this.vh - window.innerHeight) < 120) return

    this.vh = window.innerHeight
    this.set()
  }

  set() {
    document.documentElement.style.setProperty('--vh', `${this.vh / 100}px`)
    
    // Android Chrome fix for keyboard changing the viewport height
    // const metaViewport = document.querySelector("meta[name=viewport]")
    // metaViewport.setAttribute("content", metaViewport.content + ", height=" + window.innerHeight);
  }
}
