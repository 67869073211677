// Identifies touchscreens
; (function () {
  var isTouch = false //var to indicate current input type (is touch versus no touch)
  var isTouchTimer
  var curRootClass = '' //var indicating current document root class ("can-touch" or "")

  function addtouchclass(e) {
    clearTimeout(isTouchTimer)
    isTouch = true
    if (curRootClass != 'can-touch-this') { //add "can-touch' class if it's not already present
      curRootClass = 'can-touch-this'
      document.documentElement.classList.add(curRootClass)
    }
    isTouchTimer = setTimeout(function () {
      isTouch = false
    }, 500) //maintain "istouch" state for 500ms so removetouchclass doesn't get fired immediately following a touch event
  }

  function removetouchclass(e) {
    if (!isTouch && curRootClass == 'can-touch-this') { //remove 'can-touch' class if not triggered by a touch event and class is present
      isTouch = false
      curRootClass = ''
      document.documentElement.classList.remove('can-touch-this')
    }
  }

  //this event only gets called when input type is touch
  document.addEventListener('touchstart', addtouchclass, false)

  //this event gets called when input type is everything from touch to mouse/trackpad
  document.addEventListener('mouseover', removetouchclass, false)
})();