import Swiper from 'swiper';
import { Autoplay, FreeMode, Navigation } from 'swiper/modules';

// css modules to import: https://swiperjs.com/swiper-api#css-styles
import 'swiper/swiper.min.css';

// config and initialize
document.querySelectorAll('.slider__items').forEach((slider) => {
  const parent = slider.closest('.slider');
  // let slideNames = []

  const swiper = new Swiper(slider, {
    modules: [Navigation],
    grabCursor: true,
    parallax: true,
    speed: 800,
    navigation: {
      nextEl: parent.querySelector('.slider__btn--next'),
      prevEl: parent.querySelector('.slider__btn--prev'),
    },
  });
});

const logostripe = new Swiper('.logostripe', {
  modules: [Autoplay, FreeMode],
  spaceBetween: 40,
  grabCursor: true,
  a11y: false,
  freeMode: true,
  speed: 9000,
  loop: true,
  slidesPerView: 'auto',
  autoplay: {
    delay: 0.5,
    disableOnInteraction: false,
  },
});
