/**
 * Loads library dependencies
 */

// Lazysizes - automatically assign sizes attribute for imgs/pictures & possibly custom lazyloading features
import 'lazysizes'

// Identifies touchscreen - if so, adds 'can-touch-this' class to <html>
import './can-touch-this'

// Lodash - cherrypicking only the functions we need
// - debounce to listen for resize, clicks etc.
//import { debounce } from 'lodash';

// Body-scroll-lock - to prevent scroll outside of modals/menus/etc.
//import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

// basicScroll - changes CSS variables depending on the scroll position -> use the CSS variables to animate
// import * as basicScroll from 'basicscroll'

// Picturefill - for IE backwards compatibility only
import 'picturefill'

// jQuery - for legacy use only
//import $ from 'jquery'
